
import {defineComponent, ref} from "vue";
import {UploadRawFile} from "element-plus/es/components/upload/src/upload";
import {bus} from "@/modules/eventBus";
import {UploadFile, UploadProgressEvent} from "element-plus/lib/components";
import {useToast} from "vue-toastification";
import {ProblemDetails} from "@/models/ProblemDetails";
import {useUser} from "@/store/pinia/userStore";
import {Button} from "bootstrap";
import {ElMessage} from "element-plus";
import {OrganizationService} from "@/services/OrganizationService";
import {ProductionService} from "@/services/ProductionService";
import {Organization} from "@/models/organizations/Organization";
import { Production } from "@/models/productions/Production";

export default defineComponent({
  name: "LogoSettings",
  components: {Button},
  setup(){
    const imageUrl = ref('')
    const handleAvatarSuccess = (res: UploadProgressEvent, file: UploadFile) => {
      imageUrl.value = URL.createObjectURL(file.raw)
    }
    const beforeAvatarUpload = (file: UploadRawFile) => {
      const isJPG = file.type.indexOf('image') > -1
      const isLt2M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        ElMessage.error('Avatar picture must be an image')
      }
      if (!isLt2M) {
        ElMessage.error('Avatar picture size can not exceed 8MB')
      }
      return isJPG && isLt2M
    }
    const userStore = useUser();
    const toast = useToast();
    return {toast, userStore, handleAvatarSuccess, beforeAvatarUpload}
  },
  data() {
    return {
      avatarFile: {} as File,
      isLoading: false,
      isFetching: false,
      hasAvatarQueued: false,
      photoIsLoading: false,
      organization: {} as Organization,
      production: {} as Production,
    }
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    },
    hasAvatar(): boolean {
      if (this.isOrganization) {
        return this.organization.photo !== '' && this.organization.photo !== null
      } else {
        return this.production.photo !== '' && this.production.photo !== null
      }
    }
  },
  mounted(){
    this.fetch()
    bus.on('modals:cropper:handle:mobile', (blob) => {
      this.uploadPhoto(blob)
    })
  },
  unmounted() {
    bus.off('modals:cropper:handle:mobile', (blob) => {
      this.uploadPhoto(blob)
    })
  },
  methods: {
    fetch() {
      this.isFetching = true
      if (this.isOrganization) {
        OrganizationService.fetch(this.organizationId).then(response => {
          this.organization = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.isFetching = false
        })
      } else {
        ProductionService.fetch(this.productionId).then(response => {
          this.production = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.isFetching = false
        })
      }
    },
    requestCropper(xhr){
      bus.emit('modals:cropper:open', {blob: xhr.file, requester: 'modals:cropper:handle:entity-settings', isUserPhoto: false})
    },
    uploadPhoto(blob) {
      this.photoIsLoading = true
      const formData = new FormData();
      formData.append('file', blob);
      if (this.isOrganization) {
        OrganizationService.uploadPhoto(this.organizationId, formData).then(response => {
          this.organization.photo = response.data.avatarUrl
          this.toast.success('Photo Uploaded')
          this.hasAvatarQueued = false
          this.userStore.fetchOrganizationMemberships()
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.photoIsLoading = false
        })
      } else {
        ProductionService.uploadPhoto(this.productionId, formData).then(response => {
          this.production.photo = response.data.avatarUrl
          this.hasAvatarQueued = false
          this.toast.success('Photo Uploaded')
          this.userStore.fetchProductionMemberships()
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.photoIsLoading = false
        })
      }
    },
    removePhoto() {
      this.photoIsLoading = true
      if (this.isOrganization) {
        OrganizationService.removePhoto(this.organizationId).then(() => {
          this.organization.photo = ""
          this.toast.success("Photo Removed")
          this.userStore.fetchOrganizationMemberships()
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.photoIsLoading = false
        })
      } else {
        ProductionService.removePhoto(this.productionId).then(() => {
          this.production.photo = ""
          this.toast.success("Photo Removed")
          this.userStore.fetchProductionMemberships()
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.photoIsLoading = false
        })
      }
    },
  }
})
